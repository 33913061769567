<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="productModal"
    :title="$t('Create Received Product')"
    :buttons-hidden="true" autocomplete="nofill">
    <div v-if="!isCameraOpen" class="grid grid-cols-4 sm:gap-4 gap-2 mt-4 items-center">
      <div :class="!isAndroid ? 'col-span-3' : 'col-span-4'">
        <input type="file" @change="changeImage" class="hidden" ref="fileInput" accept="image/*">
        <div @click="$refs.fileInput.click()" class="upload-div text-center cursor-pointer" :class="imageShow ? 'p-3' : 'px-2 py-6'">
          <div class="text-sm font-semibold">
            <div class="flex items-center justify-center">
              <span class="material-icons-outlined text-blue text-3xl">cloud_upload</span>
              <span class="text-darkgray ml-3 mr-1 underline" @click.stop="$refs.fileInput.click()">{{$t('Click To Upload')}}</span>
            </div>
            <p class="text-grey leading-tight mt-1">{{$t('SVG, PNG, JPG or GIF (max. 800*400px - 1MP)')}}</p>
          </div>
          <div v-if="imageShow" class="flex mt-4" :class="imageShow ? '' : 'h-24'">
            <img class="rounded m-auto" :src="imageShow" alt="">
          </div>
        </div>
      </div>
      <div v-if="!isAndroid" @click="openCamera" class="cursor-pointer upload-div col-span-1 flex items-center justify-center" :class="imageShow ? 'h-28' : 'h-full'">
        <div class="text-center py-5 text-sm">
          <p class="material-icons-outlined text-blue text-3xl">photo_camera</p>
          <p class="font-semibold text-grey leading-tight">{{$t('Take Photo')}}</p>
        </div>
      </div>
    </div>
    <div v-else style="width: 500px;" class="m-auto border border-2 rounded p-3 mt-4">
      <div class="mb-2 w-full">
        <video class="rounded" v-show="!isPhotoTaken" ref="camera" width="500px" autoplay></video>
        <canvas class="rounded" v-show="isPhotoTaken" id="photoTaken" ref="canvas" width="475px" height="355px"></canvas>
      </div>
      <div class="flex justify-around">
        <p class="material-icons-round text-white bg-blue hover:shadow-drop hover:bg-white hover:text-blue-100 cursor-pointer rounded-full p-2 text-3xl" @click="closeCamera">close</p>
        <p class="material-icons-outlined text-white bg-blue hover:shadow-drop hover:bg-white hover:text-blue-100 cursor-pointer rounded-full p-2 text-3xl" @click="takePhoto">photo_camera</p>
      </div>
    </div>
    <div class="grid sm:grid-cols-3 grid-cols-1 gap-4 mt-6">
      <div>
        <v-select :class="product.box_number ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="box_number" name="customer box number" v-model="product.box_number"
          :placeholder="$t('Customer Box Number') + '*'" :options="customers"/>
        <span class="text-danger text-xs" v-show="errors.has('customer box number')">{{ errors.first('customer box number') }}</span>
      </div>
      <div>
        <v-select :class="product.shipment ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="id" name="outgoing shipment" v-model="product.shipment" v-validate="'required'"
          :placeholder="$t('Outgoing Shipment ID') + '*'" :options="shipments"/>
        <span class="text-danger text-xs" v-show="errors.has('outgoing shipment')">{{ errors.first('outgoing shipment') }}</span>
      </div>
      <div class="relative">
        <label :class="product.name ? 'visible' : 'hidden'" class="input-label" for="name">{{ $t('Product Name') + ' *' }}</label>
        <input id="name" v-validate="'required'" :placeholder="$t('Product Name') + ' *'" name="product name" v-model="product.name" :class="product.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has('product name')">{{ errors.first('product name') }}</span> 
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 mt-4" :class="otherFlag ? 'sm:grid-cols-3' : 'sm:grid-cols-2'">
      <div>
        <v-select :class="product.store ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="name" name="store" v-model="product.store" v-validate="'required'"
          :placeholder="$t('Store') + '*'" :options="stores"/>
        <span class="text-danger text-xs" v-show="errors.has('store')">{{ errors.first('store') }}</span>
      </div>
      <div v-if="otherFlag" class="relative">
        <label :class="product.other_store ? 'visible' : 'hidden'" class="input-label" for="storeName">{{ $t('Store Name') + ' *' }}</label>
        <input id="storeName" v-validate="'required'" :placeholder="$t('Store Name') + ' *'" name="store name" v-model="product.other_store" :class="product.other_store ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-xs" v-show="errors.has('store name')">{{ errors.first('store name') }}</span> 
      </div>
      <div>
        <v-select :class="product.category ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="name" name="group" v-model="product.category" v-validate="'required'"
          :placeholder="$t('Product Category') + '*'" :options="categories"/>
        <span class="text-danger text-xs" v-show="errors.has('group')">{{ errors.first('group') }}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addNewProduct" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/shipblu-components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendRequest } from '../../../axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'

export default {
  props: ['productModal', 'type', 'product'],
  data () {
    return {
      productFlags: [],
      productChannels: [],
      productGroup: [],
      merchantID: '',
      merchantName: '',
      shipments: [],
      categories: [],
      stores: [],
      customers: [],
      imageShow: '',
      image: '',
      fileUrl: '',
      otherFlag: false,
      isAndroid: false,
      isCameraOpen: false,
      isPhotoTaken: false
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  watch: {
    'product.store' (val) {
      if (val && val.name === 'Other') {
        this.otherFlag = true
      } else {
        this.otherFlag = false
        this.product.other_store = ''
      }
    },
    'product' () {
      if (this.type === 'Edit Product') {
        this.sku = this.product.sku
      }
      this.$validator.resume()
    },
    'productModal' (val) {
      if (val === true) {
        this.loadShipments()
        this.loadCategories()
        this.listStores()
        this.loadCustomeBoxNumbers()
        if (/(android)/i.test(navigator.userAgent)) {
          this.isAndroid = true
        } else {
          this.isAndroid = false
        }
      }
    }
  },
  methods: {
    openCamera () {
      this.isCameraOpen = true
      this.createCameraElement()
    },
    closeCamera () {
      this.isCameraOpen = false
      this.isPhotoTaken = false
      this.stopCameraStream()
    },
    createCameraElement () {
      const constraints = (window.constraints = { audio: false, video: true })
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
        })
    },
    stopCameraStream () {
      const tracks = this.$refs.camera.srcObject.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this.isCameraOpen = false
      this.isPhotoTaken = false
    },
    takePhoto () {
      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 475, 355)
      this.downloadImage()
    },
    downloadImage () {
      this.stopCameraStream()
      this.image = document.getElementById('photoTaken').toDataURL('image/jpeg')
      this.imageShow = this.image
      this.image = this.base64ImageToBlob(this.image)
      this.changeImage('fromCam')
    },
    base64ImageToBlob (str) {
      const pos = str.indexOf(';base64,')
      const type = str.substring(5, pos)
      const b64 = str.substr(pos + 8)
      const imageContent = atob(b64)
      const buffer = new ArrayBuffer(imageContent.length)
      const view = new Uint8Array(buffer)
      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }
      const blob = new Blob([buffer], { type })
      return blob
    },
    isImage (file) {
      if (file) {
        return /\.(png|jpg)$/.test(file.name)
      }
    },
    changeImage (type) {
      const formData = new FormData()
      if (type === 'fromCam')  {
        formData.append('file', this.image)
      } else {
        const [file] = this.$refs.fileInput.files
        if (!this.isImage(file)) {
          common.notify({
            title: i18nData[this.$i18n.locale]['Login Attempt'],
            text: i18nData[this.$i18n.locale]['Only supports upload .png, .jpg suffix files'],
            color: '#FF9F43'
          })
          return false
        }
        if (file) {
          this.image = URL.createObjectURL(file)
          this.imageShow = this.image
        }
        formData.append('file', this.$refs.fileInput.files[0])
      }
      sendRequest(this, 'api/upload-file/', 'post', formData, true,
        (response) => {
          this.fileUrl = response.data.file_url
        }
      )
    },
    loadShipments () {
      sendRequest(this, 'api/shipments/', 'get', null, true,
        (response) => {
          this.shipments = response.data
        }
      )
    },
    loadCategories () {
      sendRequest(this, 'api/product-categories/', 'get', null, true,
        (response) => {
          this.categories = response.data
        }
      )
    },
    listStores () {
      sendRequest(this, 'api/stores/', 'get', null, true,
        (response) => {
          this.stores = response.data
          this.stores.push({name: 'Other'})
        }
      )
    },
    loadCustomeBoxNumbers () {
      sendRequest(this, 'api/storage/po-boxes/', 'get', null, true,
        (response) => {
          this.customers = response.data
        }
      )
    },
    closeModal () {
      this.$validator.pause()
      this.imageShow = ''
      this.fileUrl = ''
      this.$emit('loadData')
      this.$emit('productModal', false)
      if (this.isCameraOpen) {
        this.stopCameraStream()
      }
    },
    addNewProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const productObj = {
            ...this.product,
            category: this.product.category.id,
            image_url: this.fileUrl,
            store: this.otherFlag ? this.product.other_store : this.product.store.name,
            shipment: this.product.shipment.id
          }
          sendRequest(this, 'api/products/', 'post', productObj, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/my-shipblu-view.scss"></style>