<template>
  <div>
    <div class="flex items-center justify-between">
      <p class="font-medium text-darkblue sm:text-2xl text-xl">{{$t('Received Products')}}</p>
      <button @click="openProductModal()" class="add-button bg-blue">
        <span class="text-2xl">+</span>
      </button>
    </div>
    <div class="mt-6 lg:float-left">
      <button @click="processProductModal()" class="font-medium btn text-white bg-success text-sm">
        {{$t('Process Received Products')}}
      </button>
    </div>
    <shipblu-table
      multiple
      v-model="selected"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="products"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Product ID')}}</shipblu-th>
        <shipblu-th>{{$t('Product Image')}}</shipblu-th>
        <shipblu-th>{{$t('Product Name')}}</shipblu-th>
        <shipblu-th>{{$t('Online Store')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Box No.')}}</shipblu-th>
        <shipblu-th>{{$t('Received Data')}}</shipblu-th>
        <shipblu-th>{{$t('Outgoing Shipment')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].id }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].image">
            <img @click="data[indextr].image_urls.length > 0 ? zoomImage(data[indextr].image_urls) : ''" class="product-img w-14 h-14 m-auto" :src="data[indextr].image_urls ? data[indextr].image_urls[0] : ''" alt="product image">
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].store">
            {{ data[indextr].store }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].boxNumber">
            {{ data[indextr].box_number }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].date">
            {{ data[indextr].created.split('T')[0] }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].shipment">
            {{ data[indextr].shipment ? data[indextr].shipment.id: 'N/A' }}
          </shipblu-td>

        </shipblu-tr>
      </template>
    </shipblu-table>
    <add-product :product="product" :productModal="productModal" 
      @loadData="loadData" @productModal="productModal = $event"></add-product>
    <process-products :processModal="processModal" @processModal="processModal = $event"></process-products>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/shipblu-components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/shipblu-components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/shipblu-components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/shipblu-components/ShipBluTd.vue'
import { sendRequest } from '../../axios/requestHelper'
import common from '../../assets/utils/common'
import AddProduct from './components/AddProduct.vue'
import ProcessProducts from './components/ProcessProducts.vue'

export default {
  data () {
    return {
      productModal: false,
      maximumItems: 10,
      selected: [],
      products: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      product: {},
      processModal: false
    }
  },
  methods: {
    zoomImage (url) {
      common.zoomImage(url)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadBlackListSearch)
    },
    openProductModal () {
      this.productModal = true
      this.product = {}
    },
    processProductModal () {
      this.processModal = true
    },
    closeModal () {
      this.productModal = false
      this.slot = {}
    },
    loadData () {
      sendRequest(this, 'api/products/', 'get', null, true,
        (response) => {
          this.products = response.data
        }
      )
    }
  },
  created () {
    this.loadData()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddProduct,
    ProcessProducts
  }
}
</script>